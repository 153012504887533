<template>
  <div class="pt-5">
    <modal-loading :is-loading="loading" message="パスワード更新中..." />
    <v-row justify="center">
      <div class="col-5">
        <h2 class="mb-10">新しいパスワードを設定</h2>
        <div class="mb-7">
          新しいパスワードを設定します。
          <div class="px-3">
            <v-row justify="end">
              <router-link :to="{ name: 'login' }">
                ログインページに戻る
              </router-link>
            </v-row>
          </div>
        </div>
        <v-alert v-if="resetError" text class="mb-8" color="error">
          {{ resetError }}
        </v-alert>
        <form @keyup.enter="handleReset">
          <div>
            <v-text-field
              v-model="formData.email"
              type="text"
              label="メールアドレス"
              :error-messages="errorMessages.email"
              outlined
            />
          </div>

          <div class="mb-5">
            <div class="subtitle-1 mb-2">パスワード</div>
            <password
              v-model="formData.password"
              default-class="Password__input"
              :secure-length="9"
              :required="false"
              :toggle="true"
              @feedback="passwordFeedback"
            />
            <v-alert v-if="showPasswordStrengthMessages" text color="error">
              <div
                v-for="(message, i) in passwordStrengthMessages"
                :key="i"
                class="py-1"
              >
                {{ message }}
              </div>
            </v-alert>
            <v-alert v-if="errorMessages.password" text color="error">
              {{ errorMessages.password }}
            </v-alert>
          </div>

          <div class="mb-10">
            <div class="subtitle-1 mb-2">パスワード確認</div>
            <password
              id="password_confirmation"
              v-model="formData.password_confirmation"
              default-class="Password__input"
              :badge="false"
              :show-strength-meter="false"
              :required="false"
              :toggle="true"
            />
            <v-alert
              v-if="errorMessages.password_confirmation"
              text
              class="mt-4"
              color="error"
            >
              {{ errorMessages.password_confirmation }}
            </v-alert>
          </div>

          <div class="px-3">
            <v-row justify="end">
              <v-btn color="primary" large @click="handleReset"> 設定 </v-btn>
            </v-row>
          </div>
        </form>
      </div>
    </v-row>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import * as EmailValidator from "email-validator";
import ModalLoading from "../../components/ModalLoading.vue";
import { createData } from "../../axios";

export default {
  name: "PasswordReset",

  components: {
    Password,
    ModalLoading,
  },

  data() {
    return {
      loading: false,
      formData: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errorMessages: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      resetError: "",
      japaneseItems: {
        email: "メールアドレス",
        password: "パスワード",
        password_confirmation: "パスワード確認",
      },
      passwordFeedbackMessages: [],
      passwordStrengthMessages: [],
      showPasswordStrengthMessages: false,
    };
  },

  computed: {
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0 && !this.showPasswordStrengthMessages;
    },
  },

  created() {
    this.formData.token = this.$route.params.token;
    this.formData.email = this.$route.query.email || "";
  },

  methods: {
    passwordFeedback({ suggestions, warning }) {
      this.passwordFeedbackMessages = [];
      if (suggestions.lenght !== 0) {
        suggestions.forEach((m) => {
          this.passwordFeedbackMessages.push(m);
        });
      }
      if (warning) {
        this.passwordFeedbackMessages.push(warning);
      }
    },
    handleReset() {
      this.loading = true;
      this.resetError = "";
      this.validate();
      if (this.resetError !== "" || this.showPasswordStrengthMessages) {
        this.loading = false;
        return;
      }
      if (this.isValid) {
        createData("password/reset", this.formData)
          .then((res) => {
            if (res.code === 200) {
              this.loading = false;
              this.$router.push({
                name: "login",
                query: { from: "password_reset", email: this.formData.email },
              });
            } else {
              this.loading = false;
              this.resetError =
                "パスワードリセットできませんでした。トークンが無効です。";
            }
          })
          .catch(() => {
            this.loading = false;
            this.resetError =
              "パスワードリセットできませんでした。トークンが無効です。";
          });
      } else {
        this.loading = false;
        console.log(this.isValid);
      }
    },
    validate() {
      this.errorReset();
      if (!EmailValidator.validate(this.formData.email)) {
        this.errorMessages.email = "メールアドレスが不正です。";
      }
      Object.keys(this.formData).forEach((key) => {
        this.checkRequired(key, [
          "name",
          "email",
          "password",
          "password_confirmation",
        ]);
        this.checkMaxLength(key, 32, [
          "name",
          "password",
          "password_confirmation",
        ]);
        this.checkMaxLength(key, 127, ["email"]);
        this.checkMinLength(key, 9, ["password", "password_confirmation"]);
      });
      this.checkConfirmation();
      if (
        this.passwordFeedbackMessages.length !== 0 &&
        this.formData.password.length !== 0
      ) {
        this.showPasswordStrengthMessages = true;
        this.passwordStrengthMessages = this.passwordFeedbackMessages;
      }
    },
    checkRequired(key, requiredList) {
      if (requiredList.indexOf(key) !== -1 && this.formData[key].length === 0) {
        this.errorMessages[key] = `${this.japaneseItems[key]}は必須項目です。`;
      }
    },
    checkMaxLength(key, max, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          this.formData[key] &&
          max < this.formData[key].length
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${max}文字以下で記入して下さい。`;
        }
      });
    },
    checkMinLength(key, min, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          this.formData[key] &&
          min > this.formData[key].length
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${min}文字以上で記入して下さい。`;
        }
      });
    },
    checkConfirmation() {
      if (this.formData.password !== this.formData.password_confirmation) {
        this.errorMessages["password_confirmation"] =
          "パスワードが一致しません。";
      }
    },
    errorReset() {
      this.showPasswordStrengthMessages = false;
      this.passwordStrengthMessages = [];
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
    },
  },
};
</script>
